//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "not-found",
  data() {
    return {};
  },
};
